import type { GetUserEnabledFeaturesQuery } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const GET_USER_ENABLED_FEATURES: TypedDocumentNode<GetUserEnabledFeaturesQuery> = gql`
  query GetUserEnabledFeatures {
    currentUser {
      id
      enabledFeatures
    }
  }
`;

export default GET_USER_ENABLED_FEATURES;
