import useOnboardingUser from '$/graphql/hooks/onboarding/useOnboardingUser';
import useEnabledFeatures from '$/graphql/hooks/user/useUserEnabledFeatures';

const useFeatureFlag = (
  featureName: string | undefined,
): boolean | undefined => {
  const { data: currentEnabledFeatures } = useEnabledFeatures();
  const { data: onboardingUser } = useOnboardingUser();

  const usersFeatures = currentEnabledFeatures || onboardingUser;

  // check here to see if current user has "featureName" in their features field
  const userHasFeature = featureName
    ? usersFeatures?.enabledFeatures.includes(featureName)
    : undefined;

  return userHasFeature;
};

export default useFeatureFlag;
