import UPGRADE_DATA_FRAGMENT from '$/graphql/fragments/UpgradeData';
import type { GetUpgradePlacesQuery } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const GET_UPGRADE_PLACES: TypedDocumentNode<GetUpgradePlacesQuery> = gql`
  query GetUpgradePlaces {
    currentUser {
      id
      ...UpgradeData
    }
  }

  ${UPGRADE_DATA_FRAGMENT}
`;

export default GET_UPGRADE_PLACES;
