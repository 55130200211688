/* eslint-disable @typescript-eslint/no-floating-promises */
import normalizeLease from '$/model/Lease';
import { withoutNulls } from '$/utils/withoutNulls';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import GET_USER_LEASE from '../../queries/user/getUserLease';
import initVar from '../../variables/init';
import useLoggedInStatus from '../auth/useLoggedInStatus';

export default function useCurrentLease() {
  const status = useLoggedInStatus();
  const [getUserLease, { data: raw, loading, refetch }] =
    useLazyQuery(GET_USER_LEASE);

  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.currentUser?.currentLease);
    return data && normalizeLease(data);
  }, [raw]);

  useEffect(() => {
    if (init && status === 'logged-in') {
      getUserLease();
    }
  }, [getUserLease, status, init]);

  return { data: normalized, loading, refetch };
}
