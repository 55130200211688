import type { EnabledFeaturesFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

export default function normalizeCurrentEnabledFeatures(
  input: WithoutNulls<EnabledFeaturesFragment>,
) {
  return {
    enabledFeatures: input.enabledFeatures,
  };
}

export type EnabledFeatures = ReturnType<
  typeof normalizeCurrentEnabledFeatures
>;
