import Button from '$/components/Atoms/Button';
import { useRouter } from 'next/router';
import { memo, useCallback } from 'react';

import * as Styled from './styles';
import type { Props } from './types';

function NoOfferCard({ className, hasResponsive = true }: Props): JSX.Element {
  const { push } = useRouter();

  const onButtonClick = useCallback(() => {
    void push('/browse-properties', undefined, {
      shallow: true,
    });
  }, [push]);

  return (
    <Styled.Container className={className} $hasResponsive={hasResponsive}>
      <Styled.ImageContainer $hasResponsive={hasResponsive} />
      <Styled.Content $hasResponsive={hasResponsive}>
        <Styled.CardContent>
          <Styled.Top>
            <Styled.Title>
              Looking for a new place? We&nbsp;can help!
            </Styled.Title>
            <Styled.Subtitle>
              Start searching within our network to find deals that you can’t
              find anywhere else.
            </Styled.Subtitle>
          </Styled.Top>
          <Styled.BonusWrapper>
            {[...(Array(3) as [undefined])].map((_, i) => {
              const coins = '$'.repeat(i + 1);
              return <Styled.Bonus key={`bonus-${coins}`} coins={coins} />;
            })}
          </Styled.BonusWrapper>
          <Styled.Disclaimer>
            Keep an eye out for coins like these to see how to earn more
          </Styled.Disclaimer>
        </Styled.CardContent>
        <Styled.Flex>
          <Button label="Start searching in network" onClick={onButtonClick} />
        </Styled.Flex>
      </Styled.Content>
    </Styled.Container>
  );
}

export default memo(NoOfferCard);
