import HomeStatus from '$/containers/Views/Home/types';
import { CurrentLeaseStatus } from '$/model/LeaseStatus';
import { RenewalStatus } from '$/model/RenewalStatus';
import { UpgradeStatus } from '$/model/UpgradeStatus';
import { isPast, isToday } from 'date-fns';

import { Props, ReturnProps } from './types';

const userAppStatus = ({
  upgradeStatus,
  currentLease,
  currentRenewalOffer,
}: Props): ReturnProps => {
  if (!upgradeStatus || !currentLease || !currentRenewalOffer) return {};
  const hasNewLease = [
    CurrentLeaseStatus.NEW,
    CurrentLeaseStatus.RENEWED,
  ].includes(currentLease.status);
  const renewalStatus = currentRenewalOffer.status;
  const renewalDeadline = new Date(currentRenewalOffer.effectiveTo || '');

  const m2mActive = currentLease?.monthToMonth;

  const otherStatuses = {
    renewalStatus,
    upgradeStatus,
    m2mActive,
    leaseStatus: currentLease?.status,
    renewalExpired:
      !!renewalDeadline && !isToday(renewalDeadline) && isPast(renewalDeadline),
  };

  switch (true) {
    case upgradeStatus === UpgradeStatus.INTERESTED:
      return {
        ...otherStatuses,
        homeStatus: HomeStatus.UpgradeInterested,
      };

    case hasNewLease:
      return {
        ...otherStatuses,
        homeStatus: HomeStatus.NewLeaseAccepted,
      };
    case renewalStatus === RenewalStatus.OFFERED:
      return {
        ...otherStatuses,
        homeStatus: HomeStatus.RenewalOffered,
      };
    case renewalStatus === RenewalStatus.DECLINED:
      return {
        ...otherStatuses,
        homeStatus: HomeStatus.RenewalDeclined,
      };
    case renewalStatus === RenewalStatus.INTERESTED_LATE:
      return {
        ...otherStatuses,
        homeStatus: HomeStatus.RenewalInterestedLate,
      };
    case renewalStatus === RenewalStatus.INTERESTED:
      return {
        ...otherStatuses,
        homeStatus: HomeStatus.RenewalInterested,
      };
    case renewalStatus === RenewalStatus.NOT_OFFERED:
      return {
        ...otherStatuses,
        homeStatus: HomeStatus.RenewalNotOffered,
      };
    default:
      return {
        ...otherStatuses,
        homeStatus: undefined,
      };
  }
};

export default userAppStatus;
