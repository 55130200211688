import {
  ESignatureStatus,
  FutureLease,
  SignerStatus,
} from '$/graphql/generated';
import useFeatureFlag from '$/hooks/useFeatureFlag';
import useModal from '$/hooks/useModal';
import { CurrentCommunity } from '$/model/CurrentCommunity';
import { Incentive } from '$/model/Incentive';
import { Lease } from '$/model/Lease';
import { RenewalOffer } from '$/model/RenewalOffer';
import { UpgradeData } from '$/model/UpgradeData';
import { UserSummary } from '$/model/UserSummary';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import HomeStatus from './types';

export function useDeclineOffer() {
  const { push } = useRouter();
  const handleOnClick = useCallback(() => {
    void push('/renewal-offer/decline-offer', undefined, { shallow: true });
  }, [push]);

  return { handleOnClick };
}

export const useHandlers = () => {
  const { push } = useRouter();

  const onViewWallet = useCallback(() => {
    void push('/rewards', undefined, {
      shallow: true,
    });
  }, [push]);
  const onSearch = useCallback(() => {
    void push('/browse-properties', undefined, {
      shallow: true,
    });
  }, [push]);
  const onSeeUpgradableUnits = useCallback(() => {
    void push('/upgrade-place', undefined, {
      shallow: true,
    });
  }, [push]);
  const onUpdateInfo = useCallback(() => {
    void push('/profile', undefined, {
      shallow: true,
    });
  }, [push]);
  const onViewRenewalOffers = useCallback(() => {
    void push('/renewal-offer', undefined, {
      shallow: true,
    });
  }, [push]);

  const onViewYourInterest = useCallback(() => {
    void push('/your-interest', undefined, {
      shallow: true,
    });
  }, [push]);

  return {
    onViewWallet,
    onSearch,
    onSeeUpgradableUnits,
    onUpdateInfo,
    onViewRenewalOffers,
    onViewYourInterest,
  };
};

function getLeaseSignMessage(
  futureLease?: FutureLease,
):
  | { message: string; messageLink?: { link: string; copy: string } }
  | undefined {
  if (!futureLease) return undefined;

  const isLeaseSignRequested =
    futureLease.signerStatus === SignerStatus.Requested;

  const futureLeaseSigningStatus = futureLease.signingStatus;

  if (isLeaseSignRequested)
    return {
      message: 'Your lease is ready to sign!',
      messageLink: { link: '/sign-lease', copy: 'Sign your new lease' },
    };

  switch (futureLeaseSigningStatus) {
    case ESignatureStatus.AllResidentsSigned:
      return {
        message:
          'Your property team is countersigning your lease. Check back soon!',
        messageLink: undefined,
      };
    case ESignatureStatus.SomeResidentsSigned:
      return {
        message:
          'Successful lease signature! Waiting for other residents to sign prior to finalizing lease.',
        messageLink: undefined,
      };
    case ESignatureStatus.FullyExecuted:
      return {
        message: 'Your new lease is finalized!',
        messageLink: undefined,
      };
    default:
      return undefined;
  }
}

export const useHomeStatusContent = ({
  homeStatus,
  communityName,
  futureLease,
}: {
  homeStatus?: HomeStatus;
  communityName?: string;
  futureLease?: FutureLease;
}): {
  mainText?: string;
  messageInfo?: {
    type: string;
    message: string;
  };
  messageLink?: { link: string; copy: string };
} => {
  const ntvEnabled = useFeatureFlag('NOTICE_TO_VACATE');
  const esignedNoticeToVacateEnabled = useFeatureFlag(
    'ESIGNED_NOTICE_TO_VACATE',
  );

  if (!homeStatus || !communityName) return {};

  const leaseSignMessage = getLeaseSignMessage(futureLease);

  const renewalDeclinedInfo = {
    mainText:
      'Looking for a new place? Start searching below. Plus, you can still earn rewards when you find a new place in-network.',
    messageInfo: {
      type: 'info',
      message: '',
    },
    messageLink: {
      link: '/renewal-offer',
      copy: esignedNoticeToVacateEnabled
        ? 'View notice details'
        : 'Update your offer.',
    },
  };

  switch (true) {
    // There shouldn't be more options, so default value doesn't matter
    default:
    case homeStatus === HomeStatus.RenewalOffered:
      return {
        mainText: `This is your hub where you can manage your renewal,
        handle your move-out, or find a new home in our network of properties.`,
      };
    case homeStatus === HomeStatus.RenewalDeclined &&
      esignedNoticeToVacateEnabled:
      renewalDeclinedInfo.messageInfo.message =
        'You signed your notice of intent to vacate. Questions about your household’s next steps?';
      return renewalDeclinedInfo;
    case homeStatus === HomeStatus.RenewalDeclined && ntvEnabled:
      renewalDeclinedInfo.messageInfo.message =
        'You sent notice of intent to vacate to your property. Your property will reach out with next steps.';
      return renewalDeclinedInfo;
    case homeStatus === HomeStatus.RenewalDeclined:
      renewalDeclinedInfo.messageInfo.message =
        'You declined to renew your existing lease. Your property will reach out to confirm.';
      return renewalDeclinedInfo;
    case homeStatus === HomeStatus.RenewalInterestedLate:
      return {
        mainText:
          communityName &&
          `The team at ${communityName} is working on confirming your renewal.`,
        messageInfo: {
          type: 'info',
          message: leaseSignMessage?.message
            ? leaseSignMessage.message
            : 'Renewal selected and pending. You’ll receive a lease to sign soon.',
        },
        messageLink: leaseSignMessage
          ? leaseSignMessage.messageLink
          : { link: '/renewal-offer', copy: 'View renewal details' },
      };
    case homeStatus === HomeStatus.RenewalInterested:
      return {
        mainText:
          communityName &&
          `The team at ${communityName} is working on confirming your renewal.
        They will be in touch with next steps.
        Once your renewal is finalized it will be reflected here.`,
        messageInfo: {
          type: 'info',
          message: leaseSignMessage?.message
            ? leaseSignMessage.message
            : 'Renewal selected and pending. You’ll receive a lease to sign soon.',
        },
        messageLink: leaseSignMessage
          ? leaseSignMessage.messageLink
          : { link: '/renewal-offer', copy: 'View renewal details' },
      };
    case homeStatus === HomeStatus.UpgradeInterested:
      return {
        mainText:
          communityName &&
          `The team at ${communityName} is working on confirming your upgrade request. They will be in touch via email or phone with next steps. Once your upgrade is finalized it will be reflected here.`,
        messageInfo: {
          type: 'info',
          message: 'Upgrade selected and pending',
        },
        messageLink: { link: '/renewal-offer', copy: 'Update your offer.' },
      };
    case homeStatus === HomeStatus.RenewalNotOffered:
      return {
        mainText: `Your property has not prepared your renewal offer yet,
        but we will be sure to notify you as soon as it is ready.`,
      };
    case homeStatus === HomeStatus.NewLeaseAccepted:
      return {
        mainText: 'Welcome to your new lease',
      };
  }
};

export const useCashRedemption = ({ incentive }: { incentive?: Incentive }) =>
  incentive && incentive.balance > 0;

export const useCashRedemptionForm = ({
  userSummary,
  currentLease,
  currentCommunity,
  currentRenewalOffer,
  upgradeData,
}: {
  userSummary?: UserSummary;
  currentLease?: Lease;
  currentCommunity?: CurrentCommunity;
  currentRenewalOffer?: RenewalOffer;
  upgradeData?: UpgradeData;
}) => {
  const { isModalOpen, onOpenModal, onCloseModal } = useModal();

  const hiddenFormValues = {
    user_id: userSummary?.id ?? '',
    email: userSummary?.email ?? '',
    community_id: currentCommunity?.id ?? '',
    current_community_name: currentCommunity?.name ?? '',
    lease_id: currentLease?.id ?? '',
    upgrade_unit_status: upgradeData?.upgradeStatus ?? '',
    renewal_offer_status: currentRenewalOffer?.status ?? '',
  };

  return { hiddenFormValues, isModalOpen, onOpenModal, onCloseModal };
};
