import RENEWAL_OFFER_FRAGMENT from '$/graphql/fragments/RenewalOffer';
import type { GetUserRenewalOfferQuery } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

const GET_USER_RENEWAL_OFFER: TypedDocumentNode<GetUserRenewalOfferQuery> = gql`
  query GetUserRenewalOffer {
    currentUser {
      id
      currentLease {
        id
        renewalOffer {
          id
          ...RenewalOffer
        }
      }
    }
  }
  ${RENEWAL_OFFER_FRAGMENT}
`;

export default GET_USER_RENEWAL_OFFER;
