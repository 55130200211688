import type { UpgradeDataFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';
import { parseISO } from 'date-fns';

import normalizeIncentives from './Incentive';
import { normalizeUpgradeStatus } from './UpgradeStatus';
import normalizeUpgradeUnit from './UpgradeUnit';

export default function normalizeUpgradeData(
  input: WithoutNulls<UpgradeDataFragment>,
) {
  return {
    userId: input.id,
    upgradeStatus: normalizeUpgradeStatus(input.upgradeUnitStatus),
    units: input?.upgradeableUnits
      ? input.upgradeableUnits.map(normalizeUpgradeUnit)
      : [],
    expiryDate: input.currentLease?.renewalOffer?.effectiveTo
      ? parseISO(input.currentLease.renewalOffer?.effectiveTo).getTime()
      : undefined,
    incentives: normalizeIncentives(input.incentives),
    unitUpgrade: input.unitUpgrade
      ? {
          createdAt: parseISO(input.unitUpgrade.createdAt).getTime(),
          unit: normalizeUpgradeUnit(input.unitUpgrade.unit),
        }
      : undefined,
  };
}

export type UpgradeData = ReturnType<typeof normalizeUpgradeData>;
