import UPGRADE_UNIT_FRAGMENT from '$/graphql/fragments/UpgradeUnit';
import type { UpgradeDataFragment } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import INCENTIVE_FRAGMENT from './Incentive';

const UPGRADE_DATA_FRAGMENT: TypedDocumentNode<UpgradeDataFragment> = gql`
  fragment UpgradeData on User {
    id
    upgradeUnitStatus
    upgradeableUnits {
      ...UpgradeUnit
    }
    currentLease {
      id
      renewalOffer {
        id
        effectiveTo
      }
    }
    incentives {
      ...Incentive
    }
    unitUpgrade {
      createdAt
      unit {
        ...UpgradeUnit
      }
    }
  }
  ${UPGRADE_UNIT_FRAGMENT}
  ${INCENTIVE_FRAGMENT}
`;

export default UPGRADE_DATA_FRAGMENT;
