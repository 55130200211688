import DefaultBonus from '$/components/Atoms/Bonus';
import { Body1, H4 } from '$/components/Atoms/Typography';
import { from } from '$/styles/responsive';
import styled, { css } from 'styled-components';

export const SimpleImage = styled.img`
  max-width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CardContent = styled.div`
  margin-bottom: 2rem;
  ${from.tabletLandscape`
        margin-bottom: .5rem;
  `}
`;

export const Bonus = styled(DefaultBonus)`
  &:not(:first-child) {
    margin-right: 0.275rem;
    ${from.tabletLandscape`
        margin-right: .25rem;
      `}
  }
`;

export const BonusWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.customTheme.spacing.sm};
`;

export const Flex = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  ${from.tabletLandscape`
    margin-top: auto;
  `}
`;

export const Content = styled.div<{ $hasResponsive: boolean }>`
  background: ${({ theme }) => theme.customTheme.colors.white};
  border-radius: 1rem;
  box-shadow: 0.25rem 0.75rem 2rem rgba(0, 0, 0, 0.1);
  padding: ${({ theme }) => theme.customTheme.spacing.base};

  ${({ $hasResponsive }) =>
    $hasResponsive &&
    css`
      ${from.tabletLandscape`
        display: flex;
        flex-direction: column;
        border-radius: 0 1rem 1rem 0;
        padding: ${({ theme }) => theme.customTheme.spacing.lg} ${({ theme }) =>
        theme.customTheme.spacing.base};
        width: 50%;
      `}
    `}
`;
export const Container = styled.div<{ $hasResponsive: boolean }>`
  ${({ $hasResponsive }) =>
    $hasResponsive &&
    css`
      ${from.tabletLandscape`
        display: flex;
      `}
    `}
`;

export const Subtitle = styled(Body1)`
  color: ${({ theme }) => theme.customTheme.colors.grey700};
  font-size: 1rem;
  line-height: 1.313rem;
`;

export const Disclaimer = styled(Body1)`
  color: ${({ theme }) => theme.customTheme.colors.grey700};
  font-size: 0.875rem;
  line-height: 1.313rem;
  max-width: 90%;
`;

export const Title = styled(H4)`
  margin-bottom: ${({ theme }) => theme.customTheme.spacing.sm};

  ${from.tabletLandscape`
      margin-bottom: ${({ theme }) => theme.customTheme.spacing['2xs']};
      font-size: 1.25rem;
  `}
`;

export const Top = styled.div`
  margin-bottom: ${({ theme }) => theme.customTheme.spacing.sm};
`;

export const ImageContainer = styled.div<{ $hasResponsive: boolean }>`
  background-color: ${({ theme }) => theme.customTheme.colors.cream25};
  border-radius: 1rem 1rem 0 0;
  align-items: center;
  justify-content: center;
  height: 12.5rem;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: none;

  ${({ $hasResponsive }) =>
    $hasResponsive &&
    css`
      ${from.tabletLandscape`
        display: flex;
        border-radius: 1rem 0 0 1rem;
        height: 100%;
        width: 50%;
      `}
    `}
`;
