/* eslint-disable @typescript-eslint/no-floating-promises */
import GET_UPGRADE_PLACES from '$/graphql/queries/upgradePlace/getUpgradePlaces';
import normalizeUpgradeData from '$/model/UpgradeData';
import { withoutNulls } from '$/utils/withoutNulls';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import initVar from '../../variables/init';
import useLoggedInStatus from '../auth/useLoggedInStatus';

export default function useUpgradePlaces() {
  const status = useLoggedInStatus();
  const [getUpgradePlaces, { data: raw, loading }] =
    useLazyQuery(GET_UPGRADE_PLACES);

  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.currentUser);
    return data && normalizeUpgradeData(data);
  }, [raw]);

  useEffect(() => {
    if (init && status === 'logged-in') {
      getUpgradePlaces();
    }
  }, [getUpgradePlaces, status, init]);

  return { data: normalized, loading };
}
