import type { GetHousingInterestQuery } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import COMMUNITY_SUMMARY_FRAGMENT from '../../fragments/CommunitySummary';

const GET_HOUSING_INTEREST: TypedDocumentNode<GetHousingInterestQuery> = gql`
  query GetHousingInterest {
    currentUser {
      id
      interestedHousing {
        ... on Community {
          ...CommunitySummary
        }
      }
    }
  }

  ${COMMUNITY_SUMMARY_FRAGMENT}
`;

export default GET_HOUSING_INTEREST;
