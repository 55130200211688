import addAlpha from '$/utils/addAlpha';
import Link from 'next/link';
import InfoIcon from 'remixicon-react/InformationLineIcon';
import TimeIcon from 'remixicon-react/TimeLineIcon';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $type: string }>`
  align-items: center;
  border-radius: 0.75rem;
  display: inline-flex;
  padding: ${({ theme }) => theme.customTheme.spacing.sm} 0.844rem;

  ${({ $type }) => {
    switch ($type) {
      case 'info-warn':
      case 'warning':
        return css`
          background-color: ${({ theme }) =>
            addAlpha(theme.customTheme.colors.warning400, 0.1)};
          border: 1px solid
            ${({ theme }) => theme.customTheme.colors.warning400};
          color: ${({ theme }) => theme.customTheme.colors.night900};
        `;
      default:
        return css`
          background-color: ${({ theme }) =>
            addAlpha(theme.customTheme.colors.informational400, 0.1)};
          border: 1px solid
            ${({ theme }) => theme.customTheme.colors.informational400};
          color: ${({ theme }) => theme.customTheme.colors.night900};
        `;
    }
  }}
`;

export const Icon = styled(InfoIcon)`
  height: 1.125rem;
  margin-right: ${({ theme }) => theme.customTheme.spacing.xs};
  width: 1.125rem;
  flex-shrink: 0;
  color: ${({ theme }) => theme.customTheme.colors.night900};
`;

export const ClockIcon = styled(TimeIcon)`
  height: 1.125rem;
  margin-right: ${({ theme }) => theme.customTheme.spacing.xs};
  width: 1.125rem;
  flex-shrink: 0;
  color: ${({ theme }) => theme.customTheme.colors.night900};
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.customTheme.colors.night900};
`;

export const InlineMessageLink = styled(Link)<{ $type: string }>`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.customTheme.colors.night900};

  ${({ $type }) => {
    switch ($type) {
      case 'warning':
        return css`
          margin-left: ${({ theme }) => theme.customTheme.spacing['2xs']};
        `;
      default:
        return css`
          margin-left: ${({ theme }) => theme.customTheme.spacing['2xs']};
        `;
    }
  }}
`;
