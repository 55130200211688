import type { RenewalOfferPricingsFragment } from '$/graphql/generated';

import normalizeRenewalPricing, { RenewalOfferPricing } from './RenewalPricing';
import { WithoutNulls } from '../utils/withoutNulls';

export default function normalizeRenewalPricings(
  input: WithoutNulls<RenewalOfferPricingsFragment>,
) {
  const determinePricing = (timeline: 'past' | 'future') => {
    const pricingArray = input[timeline];
    return pricingArray && pricingArray.length > 0
      ? pricingArray.map(
          (pricing) => pricing && normalizeRenewalPricing(pricing),
        )
      : [];
  };

  const pastPricing = determinePricing('past');
  const futurePricing = determinePricing('future');
  const pricings: {
    current: RenewalOfferPricing;
    past?: Array<RenewalOfferPricing | undefined>;
    future?: Array<RenewalOfferPricing | undefined>;
  } = {
    current: normalizeRenewalPricing(input.current),
  };

  pricings.future = futurePricing;
  pricings.past = pastPricing;

  return pricings;
}

export type RenewalOfferPricings = ReturnType<typeof normalizeRenewalPricings>;
