import { ChargeFrequency as RawChargeFrequency } from '$/graphql/generated';

export enum ChargeFrequency {
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
  ANNUALLY = 'ANNUALLY',
  QUARTERLY = 'QUARTERLY',
  WEEKLY = 'WEEKLY',
  DAILY = 'DAILY',
}

/* eslint-disable consistent-return */
export const normalizeChargeFrequency = (
  input: RawChargeFrequency,
): ChargeFrequency => {
  switch (input) {
    case RawChargeFrequency.Monthly:
      return ChargeFrequency.MONTHLY;
    case RawChargeFrequency.OneTime:
      return ChargeFrequency.ONE_TIME;
    case RawChargeFrequency.Annually:
      return ChargeFrequency.ANNUALLY;
    case RawChargeFrequency.Quarterly:
      return ChargeFrequency.QUARTERLY;
    case RawChargeFrequency.Weekly:
      return ChargeFrequency.WEEKLY;
    case RawChargeFrequency.Daily:
      return ChargeFrequency.DAILY;
  }
};
/* eslint-enable consistent-return */
