import type { RenewalOfferPricingTermFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

import normalizeCharge from './Charge';

export default function normalizeRenewalTerm(
  input: WithoutNulls<RenewalOfferPricingTermFragment>,
) {
  return {
    id: input.id,
    lengthInMonths: input.lengthInMonths,
    baseRent: input.baseRent,
    pointValue: input.pointValue / 100 || undefined,
    securityDeposit: input.securityDeposit || undefined,
    leaseStartDate: input.leaseStartDate,
    leaseEndDate: input.leaseEndDate,
    totalMonthlyCharge: input.totalMonthlyCharge,
    charges:
      input.charges && input.charges.length > 0
        ? input.charges.map((charge) => charge && normalizeCharge(charge))
        : undefined,
  };
}

export type RenewalOfferPricingTerm = ReturnType<typeof normalizeRenewalTerm>;
