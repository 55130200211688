import { AdvancedImage, responsive } from '@cloudinary/react';
import { CloudinaryImage } from '@cloudinary/url-gen';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { memo } from 'react';

import { Props } from './types';

function Image({ alt, className, publicId }: Props): JSX.Element {
  const cloudinaryImage = new CloudinaryImage(publicId, {
    cloudName: 'dg66itvp7',
  });
  // Request automatic format.
  cloudinaryImage.format('auto');
  cloudinaryImage.delivery(dpr('2.0'));
  cloudinaryImage.resize(scale().width('auto'));
  return (
    <AdvancedImage
      cldImg={cloudinaryImage}
      alt={alt}
      className={className}
      plugins={[responsive({ steps: 100 })]}
    />
  );
}

export default memo(Image);
