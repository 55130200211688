/* eslint-disable @typescript-eslint/no-floating-promises */
import normalizeRenewalOffer from '$/model/RenewalOffer';
import { withoutNulls } from '$/utils/withoutNulls';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import GET_USER_RENEWAL_OFFER from '../../queries/user/getUserRenewalOffer';
import initVar from '../../variables/init';
import useLoggedInStatus from '../auth/useLoggedInStatus';

export default function useRenewalOffer() {
  const status = useLoggedInStatus();
  const [getUserRenewalOffer, { data: raw, loading }] = useLazyQuery(
    GET_USER_RENEWAL_OFFER,
  );

  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.currentUser?.currentLease.renewalOffer);
    return data && normalizeRenewalOffer(data);
  }, [raw]);

  useEffect(() => {
    if (init && status === 'logged-in') {
      getUserRenewalOffer();
    }
  }, [getUserRenewalOffer, status, init]);

  return { data: normalized, loading };
}
