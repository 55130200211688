import type { GetUserLeaseQuery } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import LEASE_FRAGMENT from '../../fragments/Lease';

const GET_USER_LEASE: TypedDocumentNode<GetUserLeaseQuery> = gql`
  query GetUserLease {
    currentUser {
      id
      currentLease {
        ...Lease
      }
    }
  }

  ${LEASE_FRAGMENT}
`;

export default GET_USER_LEASE;
