import UpgradeSection from '$/components/Molecules/UpgradeSection';
import ArrowRight from 'remixicon-react/ArrowRightSLineIcon';

import useConnect from './connect';
import * as Styled from '../styles';

const OtherAvailableHousing = (): JSX.Element => {
  const {
    interestedHousing,
    isDesktop,
    currentCommunity,
    handle,
    statusOptions: {
      renewalUpgradesOfferedAvailable,
      interestedHousingAvailableViewable,
    },
  } = useConnect();
  return (
    <>
      {renewalUpgradesOfferedAvailable && (
        <UpgradeSection
          greaterLength={0}
          handleSeeAll={handle.seeUpgradableUnits}
        >
          <Styled.SectionTitle>Want to upgrade your place?</Styled.SectionTitle>
          <Styled.MainInfo>
            Looking for something new, but not a new address? View available
            units at {currentCommunity?.name}
          </Styled.MainInfo>
        </UpgradeSection>
      )}
      {interestedHousingAvailableViewable && (
        <Styled.Pending>
          {isDesktop && (
            <Styled.UpgradeHeader>
              <Styled.UpgradeInfo>
                <Styled.SectionTitle>Your interests</Styled.SectionTitle>
                <Styled.MainInfo>
                  Here&apos;s a list of the places you submitted your interest
                  to:
                </Styled.MainInfo>
              </Styled.UpgradeInfo>
              {interestedHousing && interestedHousing.length > 3 && (
                <Styled.SeeMoreWrapper>
                  <Styled.Button
                    variant="floating"
                    label="See more"
                    onClick={handle.viewYourInterest}
                  />
                  <ArrowRight />
                </Styled.SeeMoreWrapper>
              )}
            </Styled.UpgradeHeader>
          )}
          {!isDesktop && (
            <Styled.SearchSection>
              <Styled.SectionTitle>Still searching?</Styled.SectionTitle>
              <Styled.Button
                label="Continue searching"
                onClick={handle.search}
                variant="secondary"
              />
              <Styled.SearchInfo>
                Here&apos;s a list of the places you submitted your interest to:
              </Styled.SearchInfo>
            </Styled.SearchSection>
          )}
          <Styled.PendingGrid>
            {interestedHousing?.slice(0, 3).map((interestedCommunity) => (
              <Styled.CardLink
                key={interestedCommunity.id}
                href={`/community/${interestedCommunity.id}`}
                passHref
              >
                <Styled.NewLeaseCard
                  key={interestedCommunity.id}
                  address={interestedCommunity.address.street}
                  bathroomsMin={interestedCommunity.bathRange.min}
                  bathroomsMax={interestedCommunity.bathRange.max}
                  bedroomsMin={interestedCommunity.bedsRange.min}
                  bedroomsMax={interestedCommunity.bedsRange.max}
                  city={interestedCommunity.address.city}
                  imageUrl={interestedCommunity.coverImageId}
                  postalCode={interestedCommunity.address.zipCode}
                  priceMax={interestedCommunity.rentRange.max}
                  priceMin={interestedCommunity.rentRange.min}
                  renewPending
                  state={interestedCommunity.address.state}
                  title={interestedCommunity.name}
                  dateToRenew={interestedCommunity.availabilityDate}
                />
              </Styled.CardLink>
            ))}
            {!isDesktop &&
              interestedHousing &&
              interestedHousing.length > 3 && (
                <Styled.Button
                  label="See more"
                  onClick={handle.viewYourInterest}
                  variant="secondary"
                />
              )}
          </Styled.PendingGrid>
        </Styled.Pending>
      )}
    </>
  );
};

export default OtherAvailableHousing;
