import { RenewalOfferStatus } from '$/graphql/generated';

export enum RenewalStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  INTERESTED = 'INTERESTED',
  INTERESTED_LATE = 'INTERESTED_LATE',
  OFFERED = 'OFFERED',
  NOT_OFFERED = 'NOT_OFFERED',
}

export const normalizeRenewalStatus = (
  input: RenewalOfferStatus | undefined,
): RenewalStatus => {
  switch (input) {
    case RenewalOfferStatus.Accepted:
      return RenewalStatus.ACCEPTED;
    case RenewalOfferStatus.Declined:
      return RenewalStatus.DECLINED;
    case RenewalOfferStatus.Interested:
      return RenewalStatus.INTERESTED;
    case RenewalOfferStatus.InterestedLate:
      return RenewalStatus.INTERESTED_LATE;
    case RenewalOfferStatus.Offered:
      return RenewalStatus.OFFERED;
    case RenewalOfferStatus.NotOffered:
      return RenewalStatus.NOT_OFFERED;
    default:
      return RenewalStatus.NOT_OFFERED;
  }
};
