import { memo } from 'react';

import * as Styled from './styles';
import { Props } from './types';

function InlineMessage({ className, children, type }: Props): JSX.Element {
  function icon(): unknown {
    switch (type) {
      case 'warning':
        return <Styled.ClockIcon />;
      default:
        return <Styled.Icon />;
    }
  }

  return (
    <Styled.Container className={className} $type={type}>
      <>
        {icon()}
        <Styled.Info>{children}</Styled.Info>
      </>
    </Styled.Container>
  );
}

export default memo(InlineMessage);
