/* eslint-disable @typescript-eslint/no-floating-promises */
import normalizeCurrentEnabledFeatures from '$/model/CurrentEnabledFeatures';
import { withoutNulls } from '$/utils/withoutNulls';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import GET_USER_ENABLED_FEATURES from '../../queries/user/getUserEnabledFeatures';
import initVar from '../../variables/init';
import useLoggedInStatus from '../auth/useLoggedInStatus';

export default function useEnabledFeatures() {
  const status = useLoggedInStatus();
  const [getUserEnabledFeatures, { data: raw, loading }] = useLazyQuery(
    GET_USER_ENABLED_FEATURES,
  );

  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.currentUser);
    return data && normalizeCurrentEnabledFeatures(data);
  }, [raw]);

  useEffect(() => {
    if (init && status === 'logged-in') {
      getUserEnabledFeatures();
    }
  }, [getUserEnabledFeatures, status, init]);

  return { data: normalized, loading };
}
