import type { InterestedHousingFragment } from '$/graphql/generated';
import type { WithoutNulls } from '$/utils/withoutNulls';

import normalizeCommunitySummary from './CommunitySummary';

export default function normalizeHousingInterest(
  input: WithoutNulls<InterestedHousingFragment>,
) {
  return {
    interestedHousing: input?.interestedHousing.flatMap((housing) => {
      if (housing !== null && housing.__typename === 'Community') {
        return normalizeCommunitySummary(housing);
      }
      return [];
    }),
  };
}

export type RenewalData = ReturnType<typeof normalizeHousingInterest>;
