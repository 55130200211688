import type { RenewalOfferPricingFragment } from '$/graphql/generated';
import { parseISO } from 'date-fns';

import normalizeRenewalTerm from './RenewalTerm';
import { WithoutNulls } from '../utils/withoutNulls';

export default function normalizeRenewalPricing(
  input: WithoutNulls<RenewalOfferPricingFragment>,
) {
  return {
    id: input.id,
    createdAt: parseISO(input.createdAt).getTime(),
    effectiveTo: parseISO(input.effectiveTo).getTime(),
    effectiveFrom: parseISO(input.effectiveFrom).getTime(),
    terms: input.terms
      ? input.terms.map((term) => normalizeRenewalTerm(term))
      : [],
  };
}

export type RenewalOfferPricing = ReturnType<typeof normalizeRenewalPricing>;
