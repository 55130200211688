/* eslint-disable @typescript-eslint/no-floating-promises */
import normalizeIncentives from '$/model/Incentive';
import { withoutNulls } from '$/utils/withoutNulls';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import GET_USER_INCENTIVES from '../../queries/user/getUserIncentives';
import initVar from '../../variables/init';
import useLoggedInStatus from '../auth/useLoggedInStatus';

export default function useCurrentIncentives() {
  const status = useLoggedInStatus();
  const [getUserIncentives, { data: raw, loading }] =
    useLazyQuery(GET_USER_INCENTIVES);

  const init = useReactiveVar(initVar); // init is false until apollo determines logged-in state for the first time

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.currentUser?.incentives);
    return data && normalizeIncentives(data);
  }, [raw]);

  useEffect(() => {
    if (init && status === 'logged-in') {
      getUserIncentives();
    }
  }, [getUserIncentives, status, init]);

  return { data: normalized, loading };
}
