import type { GetUserIncentivesQuery } from '$/graphql/generated';
import { gql, TypedDocumentNode } from '@apollo/client';

import INCENTIVE_FRAGMENT from '../../fragments/Incentive';

const GET_USER_INCENTIVES: TypedDocumentNode<GetUserIncentivesQuery> = gql`
  query GetUserIncentives {
    currentUser {
      id
      incentives {
        ...Incentive
      }
    }
  }

  ${INCENTIVE_FRAGMENT}
`;

export default GET_USER_INCENTIVES;
