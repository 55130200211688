import coinBig1x from '$/assets/icons/coinBig1x.svg';
import React, { memo } from 'react';

import * as Styled from './styles';
import type { Props } from './types';

const src = {
  big: coinBig1x,
};

function Bonus({ className, coins }: Props): JSX.Element {
  const Icon = src.big;
  const { length } = coins;
  return (
    <Styled.Container className={className}>
      <Styled.MultiplierValue
        $length={length}
      >{`${coins}`}</Styled.MultiplierValue>
      <Icon aria-label={`${coins}x booster`} />
    </Styled.Container>
  );
}

export default memo(Bonus);
