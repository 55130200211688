import analytics from '$/analytics';
import Loader from '$/components/Molecules/FullScreenLoader';
import { InlineMessageLink } from '$/components/Molecules/InlineMessage/styles';

import useConnect from './connect';
import DetailedCards from './DetailedCards';
import OtherAvailableHousing from './OtherAvailableHousing';
import * as Styled from './styles';
import HomeStatus from './types';

const HomeView = (): JSX.Element => {
  const {
    amount,
    handle,
    homeStatus,
    leaseStatus,
    isCashRedeemable,
    isDesktop,
    loading,
    mainText,
    messageInfo,
    messageLink,
    user,
    currentIncentives,
    currentLease,
    currentRenewalOffer,
    statusOptions: { renewalOffered, expiredRenewalOffered, renewalDeclined },
  } = useConnect();

  if (loading) {
    return <Loader />;
  }

  const reversed =
    homeStatus === HomeStatus.NewLeaseAccepted ||
    (renewalDeclined && !isDesktop);

  return (
    <Styled.Container topBarWithoutBorder showFooter>
      <Styled.Header $reversed={reversed}>
        {expiredRenewalOffered && (
          <Styled.InlineMessage type="warning">
            <Styled.Text>
              Your renewal deadline has passed, it&apos;s not too late to make a
              decision!{' '}
              <InlineMessageLink $type="warning" href="/renewal-offer">
                View offers
              </InlineMessageLink>
            </Styled.Text>
          </Styled.InlineMessage>
        )}

        {messageInfo && (
          <Styled.InlineMessage type={messageInfo?.type}>
            <Styled.Text>
              {messageInfo?.message}
              {messageLink && (
                <InlineMessageLink
                  $type={messageInfo?.type}
                  href={messageLink?.link}
                  onClick={() => {
                    analytics?.track('app.link.clicked', {
                      label: messageLink.copy,
                    });
                  }}
                >
                  {messageLink.copy}
                </InlineMessageLink>
              )}
            </Styled.Text>
          </Styled.InlineMessage>
        )}
        <Styled.HeaderContent>
          {!renewalDeclined && user && (
            <Styled.LogoWrapper>
              <Styled.Logo publicId={user.communityLogoId ?? ''} alt="Logo" />
            </Styled.LogoWrapper>
          )}
          <div>
            {user?.firstName && (
              <Styled.Title>
                Hey {user?.firstName},{' '}
                {renewalOffered && isDesktop && `it's time to renew.`}
                {renewalDeclined && 'we can still help!'}
              </Styled.Title>
            )}

            {renewalOffered && !isDesktop && (
              <Styled.SectionTitleGrey>
                It&apos;s time to renew.
              </Styled.SectionTitleGrey>
            )}
            <Styled.MainInfo>{mainText}</Styled.MainInfo>
          </div>
        </Styled.HeaderContent>
        {renewalDeclined && (
          <Styled.Button
            label="Start searching in network"
            onClick={handle.search}
          />
        )}
      </Styled.Header>

      <Styled.Content $noMovingSection={!renewalOffered}>
        {currentIncentives &&
          homeStatus &&
          currentLease &&
          currentRenewalOffer && (
            <DetailedCards
              reversed={reversed}
              homeStatus={homeStatus}
              leaseStatus={leaseStatus}
              handle={handle}
              currentRenewalOffer={currentRenewalOffer}
              isCashRedeemable={isCashRedeemable}
              cashCardText={
                amount >= 0 ? (
                  <span>
                    Questions about your rewards earnings?{' '}
                    <Styled.TextLink href="/help">
                      View rewards help
                    </Styled.TextLink>
                  </span>
                ) : undefined
              }
              currentIncentives={currentIncentives}
              currentLease={currentLease}
            />
          )}
        <OtherAvailableHousing />
      </Styled.Content>
      {renewalOffered && (
        <Styled.Moving
          title="Moving? We can help!"
          mainInfo="Find a new place in network and earn rewards."
          buttonLabel="Search in network"
          onClick={handle.search}
        />
      )}
    </Styled.Container>
  );
};

export default HomeView;
