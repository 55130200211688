enum HomeStatus {
  // Renewal
  RenewalOffered = 'RenewalOffered',
  RenewalNotOffered = 'RenewalNotOffered',
  RenewalInterested = 'RenewalInterested',
  RenewalInterestedLate = 'RenewalInterestedLate',
  RenewalDeclined = 'RenewalDeclined',
  // Upgrade
  UpgradeInterested = 'UpgradeInterested',
  // New Lease
  NewLeaseAccepted = 'NewLeaseAccepted',
  NewLeaseInterested = 'NewLeaseInterested',
}

export default HomeStatus;
