import { ChargeType as RawChargeType } from '$/graphql/generated';

export enum ChargeType {
  FEE = 'FEE',
  RENTABLE_ITEM = 'RENTABLE_ITEM',
  DISCOUNT = 'DISCOUNT',
}

/* eslint-disable consistent-return */
export const normalizeChargeType = (input: RawChargeType): ChargeType => {
  switch (input) {
    case RawChargeType.Fee:
      return ChargeType.FEE;
    case RawChargeType.RentableItem:
      return ChargeType.RENTABLE_ITEM;
    case RawChargeType.Discount:
      return ChargeType.DISCOUNT;
  }
};
/* eslint-enable consistent-return */
