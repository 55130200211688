import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & svg {
    vertical-align: top;
  }
`;

export const MultiplierValue = styled.div<{
  $length: number;
}>`
  position: absolute;
  font-weight: bold;
  color: #161616;

  ${({ $length }) => {
    switch ($length) {
      case 3:
        return css`
          font-size: 0.75em;
        `;
      case 4:
        return css`
          font-size: 0.5em;
        `;
      default:
        return css`
          font-size: 0.75em;
        `;
    }
  }}
`;
