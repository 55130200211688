import GET_HOUSING_INTEREST from '$/graphql/queries/housingInterest/getHousingInterest';
import normalizeHousingInterest from '$/model/HousingInterest';
import { withoutNulls } from '$/utils/withoutNulls';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

export default function useInterestedHousing() {
  const { data: raw } = useQuery(GET_HOUSING_INTEREST);

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.currentUser);
    return data?.interestedHousing && normalizeHousingInterest(data);
  }, [raw]);

  return { data: normalized };
}
