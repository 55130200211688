import useInterestedHousing from '$/graphql/hooks/interestedHousing/useInterestedHousing';
import useUpgradePlaces from '$/graphql/hooks/upgradePlaces/useUpgradePlaces';
import useCurrentCommunity from '$/graphql/hooks/user/useUserCommunity';
import useCurrentLease from '$/graphql/hooks/user/useUserLease';
import useAppStatus from '$/hooks/useAppStatus';
import { UpgradeStatus } from '$/model/UpgradeStatus';
import { from } from '$/styles/responsive';
import { useMediaQuery } from '@mui/material';

import useRenewalOffer from '../../../../graphql/hooks/user/useUserRenewalOffer';
import { useHandlers } from '../logic';
import HomeStatus from '../types';

export default function useConnect() {
  const isDesktop = useMediaQuery(from.tabletLandscape.query);
  const { data: housingInterest } = useInterestedHousing();
  const { data: currentLease } = useCurrentLease();
  const { data: currentCommunity } = useCurrentCommunity();
  const { data: currentRenewalOffer } = useRenewalOffer();

  const { data: upgradeData } = useUpgradePlaces();

  const interestedHousing = housingInterest?.interestedHousing;

  const appStatus = useAppStatus({
    upgradeStatus: upgradeData?.upgradeStatus,
    currentLease,
    currentRenewalOffer,
  });

  const { homeStatus, upgradeStatus } = appStatus;

  const renewalOffered = homeStatus === HomeStatus.RenewalOffered;
  const interestedHousingAvailableViewable =
    interestedHousing &&
    interestedHousing.length > 0 &&
    homeStatus !== HomeStatus.NewLeaseAccepted;

  const renewalUpgradesOfferedAvailable =
    upgradeStatus === UpgradeStatus.OFFERED && renewalOffered;

  const { onSearch, onSeeUpgradableUnits, onViewYourInterest } = useHandlers();

  return {
    isDesktop,
    handle: {
      search: onSearch,
      seeUpgradableUnits: onSeeUpgradableUnits,
      viewYourInterest: onViewYourInterest,
    },
    interestedHousing,
    currentCommunity,
    statusOptions: {
      interestedHousingAvailableViewable,
      renewalUpgradesOfferedAvailable,
    },
  };
}
